<template>
  <Dialog v-model="d_open" @close="close">
    <template #header>
      <i18n path="plastic.title" class="fullwidth" />
    </template>
    <template>
      <table class="rekvisity-table">
        <tr>
          <td colspan="2">
            <div class="data-div" style="display: flex;">
              <div style="width:65%;text-align:left;">
                <i18n path="plastic.date" />
              </div>
              <div style="width:35%;text-align:right;">{{new Date().toLocaleDateString("lv-LV")}}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <hr align="center" />
          </td>
        </tr>
        <tr>
          <th colspan="2" class="dialog-header">
            <i18n path="plastic.rek0" />
          </th>
        </tr>
        <tr>
          <td align="left" class="table-td-1">
            <i18n path="plastic.rek11" />
          </td>
          <th align="right">
            <i18n path="plastic.rek12" />
          </th>
        </tr>
        <tr>
          <td align="left" class="table-td-1">
            <i18n path="plastic.rek21" />
          </td>
          <th align="right">
            <i18n path="plastic.rek22" />
          </th>
        </tr>
        <tr>
          <td align="left" class="table-td-1">
            <i18n path="plastic.rek31" />
          </td>
          <th align="right">
            <i18n path="plastic.rek32" />
          </th>
        </tr>
        <tr>
          <td align="left" class="table-td-1">
            <i18n path="plastic.rek41" />
          </td>
          <td
            align="right"
            class="raleway-black-torea-bay-40px"
          >{{$store.getters["passdata"].name+' '+$store.getters["passdata"].surname}}</td>
        </tr>
        <tr>
          <td colspan="2">
            <hr align="center" />
          </td>
        </tr>
        <tr>
          <td colspan="2" align="right">
            <i18n path="plastic.kopa" class="raleway-black-torea-bay-40px" />
          </td>
        </tr>
        <tr>
          <td colspan="2" style="padding-top:20px;" class="table-td-1">
            <span v-html="$t('plastic.get1')"></span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <span v-html="$t('plastic.get2')"></span>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="table-td-1">
            <span v-html="$t('plastic.get3')"></span>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="table-td-1">
            <span v-html="$t('plastic.get4')"></span>
          </td>
        </tr>
        <tr>
          <td colspan="2" align="rigth" style="padding-top: 25px;">
            <input
              type="text"
              name="email"
              v-model="email"
              style="font-size:40px;width: 100%;padding: 20px;"
              :placeholder="$t('plastic.email')"
            />
          </td>
        </tr>
      </table>
    </template>
    <template #footer>
      <div class="dialog-footer">
        <div
          class="custom-button dialog-send-button raleway-black-torea-bay-40px-xxxl"
          v-if="sendAvailable"
        >
          <img src="@/assets/plastic-card.png" alt class="dialog-plastic-card-image" />
          <i18n path="plastic.order" @click="sendData" class="dialog-send-button" />
        </div>
        <div v-if="!sendAvailable">
          <div style="margin-bottom: 20px;">
            <i18n path="plastic.closeText" />
          </div>
          <div class="custom-button dialog-ok-button raleway-black-selago-40px">
            <i18n path="plastic.close" @click="close" />
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog.vue";

export default {
  components: { Dialog },
  props: ["value"],
  data() {
    return {
      sendAvailable: true,
      email: null
    };
  },
  computed: {
    d_open: {
      get() {
        return this.value;
      },
      set() {},
    },
  },
  methods: {
    close() {
      this.$emit("close");
      this.$emit("input", false);
    },
    sendData() {
      let data = this.$store.getters["passdata"];
      if(this.email) data.email = this.email;
      this.$api.sendPlasticOrder(data).then(() => {
        this.sendAvailable = false;
      });
    },
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  height: 145px;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(216, 122, 122);
  color: rgb(65, 52, 52);
  text-align: center;
}
.link-text {
  position: relative;
  top: -120px;
  left: 250px;
  width: 800px;
}

.fullwidth {
  width: 100%;
}
.plastic-card-image {
  width: 190px;
  display: flex;
  margin: 14px;
}
.dialog-plastic-card-image {
  height: 130px;
  margin-right: 20px;
  margin-left: 38px;
}
.send-button {
  margin: 30px;
  padding: 40px;
  background-color: burlywood;
}
.dialog-block-1 {
  font-size: 60px;
  margin-bottom: 20px;
  text-align: justify;
}
.dialog-block-2 {
  font-size: 40px;
  margin-bottom: 20px;
  text-align: justify;
}
.dialog-amt {
  font-weight: bold;
  font-size: 55px;
}
.dialog-send-button {
  display: flex;
  border-radius: 30px !important;
  padding: 30px;
  background-color: antiquewhite;
}
.dialog-header {
  /* padding: 20px; */
  font-weight: bold;
  font-size: 55px;
}
table td {
  padding-bottom: 0.5em;
}
.table-td-1 {
  font-size: 37px;
}
.rekvisity-table {
  width: 100%;
}
.dialog-ok-button {
  background-color: var(--torea-bay-2);
  letter-spacing: 7px;
  padding: 20px;
  border-radius: 20px;
}
</style>