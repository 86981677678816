<template>
  <table class="main-table">
    <tr>
      <td>
        <i18n path="result.name" />
      </td>
      <td align="right" class="raleway-black-torea-bay-40px">{{$store.getters["passdata"].name}}</td>
    </tr>
    <tr>
      <td>
        <i18n path="result.surname" />
      </td>
      <td align="right" class="raleway-black-torea-bay-40px">{{$store.getters["passdata"].surname}}</td>
    </tr>
    <tr v-if="$store.getters['passdata'].valid">
      <td>
        <i18n path="result.valid" />
      </td>
      <td align="right" class="raleway-black-torea-bay-40px">{{$store.getters["passdata"].valid}}</td>
    </tr>
    <tr v-if="$store.getters['passdata'].total">
      <td>
        <i18n path="result.step" />
      </td>
      <td align="right" class="raleway-black-torea-bay-40px">
        <i18n path="result.steps">
          <template #total>{{$store.getters["passdata"].total}}</template>
          <template #step>{{$store.getters["passdata"].step}}</template>
        </i18n>
      </td>
    </tr>
    <tr v-if="$store.getters['passdata'].created">
      <td>
        <i18n path="result.created" />
      </td>
      <td align="right" class="raleway-black-torea-bay-40px">{{$store.getters["passdata"].created}}</td>
    </tr>
  </table>
</template>

<script>
export default {};
</script>

<style soped>
.main-table {
  width: 100%;
}
</style>